// frontend/src/hooks/CheckAttendance.js
import { useState, useEffect } from 'react';
import axios from '../axios';

const useCheckAttendance = () => {  
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  // const token = process.env.REACT_APP_JWT_USER_TOKEN;
  const [token, setToken] = useState(null);
  const [tenant, setTenant] = useState(null);
  const [attendanceStatus, setAttendanceStatus] = useState([]);
  
  useEffect(() => {
    // 로컬 스토리지에서 토큰을 검색하고 상태를 업데이트합니다.
    const storedToken = localStorage.getItem('token');
    const storedTenant = localStorage.getItem('tenant');

    if (storedToken) {
      setToken(storedToken);
    }
    if (storedTenant) {
      setTenant(storedTenant);
    }
  }, []);

  useEffect(() => {
    const fetchAttendanceStatus = async () => {
      try {
        const headers =  {
          headers: {
            'tenant': tenant,
            'Authorization': `Bearer ${token}`
          }
        };
        const response = await axios.get(`${serverUrl}users/avaliable`, headers);
        //console.log('CheckAttendance - fetchAttendanceStatus:', response.data);
        // setAttendanceStatus(response.data.users.map(user => user.email));
        setAttendanceStatus(response.data.users.map(user => user._id));
        //console.log('attendanceStatus:', attendanceStatus)
      } catch (error) {
        console.error('Error fetching attendance status:', error);
      }
    };

    if (token) {    
      fetchAttendanceStatus();
    }
  }, [token]);

  /*
  useEffect(() => {
    console.log('Updated attendanceStatus:', attendanceStatus);
  }, [attendanceStatus]);
  */
 
  return attendanceStatus;
};

export default useCheckAttendance;
