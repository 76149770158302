// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password {
  position: relative; }
  .password .icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer; }
`, "",{"version":3,"sources":["webpack://./src/Components/passwordInput/PasswordInput.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB,EAAA;EADpB;IAII,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe,EAAA","sourcesContent":[".password {\n  position: relative;\n\n  .icon {\n    position: absolute;\n    top: 1rem;\n    right: 1rem;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
