// frontend/src/Components/LoginPage.js.v2
// https://github.com/Kartik-Katkar/SecureAuth/blob/main/frontend/src/pages/auth/Login.js
import React, { useEffect, useState } from "react";
import { BiLogIn } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import Card from "../Components/card/Card";
import Loader from "../Components/loader/Loader";
// import PasswordInput from "../Components/passwordInput/PasswordInput";
import styles from "./auth.module.scss";

import kakaoIcon from "../images/kakao.png";
// import useUserLogin from "../hooks/UserLogin";
import useGetUserInfo from '../hooks/GetUserInfo';

import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

const LoginPage = () => {
  // const storedEmail = localStorage.getItem('email');

  const storedToken = localStorage.getItem('token');
  // const { login, token, error } = useUserLogin();
  const { loading, isAdmin } = useGetUserInfo(storedToken);
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const navigate = useNavigate();

  // const { isLoading, isLoggedIn, isSuccess, message, isError, twoFactor } =
  const { isLoading } =
    useSelector((state) => state.auth);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!isLoggingIn) {
  //     setIsLoggingIn(true);
  //     await login(email, password);

  //     // setIsLoggingIn(false);
  //   }
  // };

  // 로그인 상태 확인 및 리디렉션
  useEffect(() => {
    // if (!isLoggingIn && storedToken) {
    if (!loading && storedToken) {
      if (isAdmin === true) {
        navigate("/overview");
      } else {
        navigate("/manage");
      }
    }
  }, [storedToken, loading, isAdmin, navigate, isLoggingIn])

  // useEffect(() => {
  //   if (storedEmail) {
  //     navigate("/overview");
  //   }
  // }, [storedEmail, navigate]);
  
  const loginWithKakao= () => {
    if (!isLoggingIn) {
      setIsLoggingIn(true);
      const redirectUrl = `${process.env.REACT_APP_REDIRECT_URL}`
      const clientId = `${process.env.REACT_APP_RESTAPI_KAKAO_APP_KEY}`
      const kakaoAuthUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=code`;
      
      // console.log('redirectUrl:', redirectUrl);
      // console.log('clientId:', clientId);
      window.location.href = kakaoAuthUrl;

      // setIsLoggingIn(false);
    }
  };

  return (
    <div>
      <Navbar />

      <div className={`container ${styles.auth}`}>
        {isLoading && <Loader />}
        <Card>
          <div className={styles.form2}>
            <div className="--flex-center">
              <BiLogIn size={35} color="#999" />
            </div>
            <h2>Login</h2> {/*<br/><br/><br/> */}
            <div className="--flex-center">
              <img src={kakaoIcon} className={`${styles.loginButton2} ${styles.kakaotalk2}`} alt="" onClick={() => loginWithKakao()} />
              {/* <div className={`${styles.loginButton2} ${styles.kakaotalk2}`} onClick={() => loginWithKakao()}>
                Kakao
              </div> */}
            </div>

            {/* <p className="--text-center --fw-bold">or</p> */}

            {/* <form onSubmit={loginUser}> */}
            {/* <form onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Email"
                required
                name="email"
                // value={email}
                // onChange={handleInputChange}
                onChange={(e) => setEmail(e.target.value)}
              />
              <PasswordInput
                placeholder="Password"
                name="password"
                // value={password}
                // onChange={handleInputChange}
                onChange={(e) => setPassword(e.target.value)}
              />

              <button type="submit" className="--btn --btn-primary --btn-block">
                Login
              </button>
            </form> */}
            <Link to="/forgot">Forgot Password</Link>
            {/* <span className={styles.register}>
              <Link to="/">Home</Link>
              <p> &nbsp; Don't have an account? &nbsp;</p>
              <Link to="/register">Register</Link>
            </span> */}
          </div>
        </Card>
      </div>

      <Footer />
    </div>
  );
};

export default LoginPage;
