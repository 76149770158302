// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card_card__fNmvo {
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  overflow: hidden; }
`, "",{"version":3,"sources":["webpack://./src/Components/card/Card.module.scss"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,6BAA6B;EAC7B,gBAAgB,EAAA","sourcesContent":[".card {\n  border: 1px solid transparent;\n  border-radius: 5px;\n  box-shadow: var(--box-shadow);\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Card_card__fNmvo`
};
export default ___CSS_LOADER_EXPORT___;
