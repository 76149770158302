// frontend/src/hooks/GetUserInfo.js
import { useState, useEffect } from "react";
// import axios from 'axios';
import axios from '../axios';

const useGetUserInfo = (token) => {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const [userName, setUserName] = useState("");
  const [userImageUrl, setUserImageUrl] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  // const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  const tenant = localStorage.getItem('tenant');

  const sendGetUserInfoReq = async () => {
    try {
      //console.log('sendGetUserInfoReq =========');
      if (token && userId) {
        // console.log('token:', token);
        // console.log('userId:', userId);

        // 관리자만 해당 기능 수행 가능
        setLoading(true);
        
        const headers = { 
          headers: {
            tenant: tenant,
            'Authorization': `Bearer ${token}`
          }
        };
        const response = await axios.get(`${serverUrl}users/${userId}`, headers);

        if (response.status === 200) {
          //console.log('res:', response.data);

          setLoading(false);
          setIsAdmin(response.data.isAdmin);
          localStorage.setItem('isAdmin', response.data.isAdmin); // 로컬스토리지에 저장
          setUserName(response.data.name);
          
          if (response.data.imageURL) {
            setUserImageUrl(response.data.imageURL);
          } else {
            setUserImageUrl('');
          }
        }
      }
    } catch (error) {
      console.error('Error Get User Info:', error);
    }
  };

  useEffect(() => {
    sendGetUserInfoReq();
  }, [token]);

  // return { userName, isAdmin, userImageUrl, loading };
  // return { sendGetUserInfoReq };
  return { sendGetUserInfoReq, userName, isAdmin, userImageUrl, loading };
};

export default useGetUserInfo;
