// /frontend/src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";

import PrivateRoute from './Components/PrivateRoute';
import { Provider } from "react-redux";
import { store } from "./redux/store";

import Attendance from "./pages/Attendance";
import LoginPage from "./pages/LoginPage";
// import DataLoader from "./Components/DataLoader";
// import FileUpload from "./Components/FileUpload";
// import ImportComponents from "./pages/ImportComponents";
import VideoInput from "./Components/VideoInput";

import RegisterUserPage from "./pages/RegisterUserPage";
import KakaoCallback from "./Components/KakaoCallback";
import Reset from "./pages/Reset";
import Forgot from "./pages/Forgot";
import Register from "./pages/Register";
import RegisterCompany from "./pages/RegisterCompany";

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/overview",
    element: (
      <PrivateRoute adminOnly={true}>
        <App />
      </PrivateRoute>
    ),
  },
  {
    path: "/attendance",
    element: (
      <PrivateRoute adminOnly={true}>
        <Attendance />
      </PrivateRoute>
    ),
  },
  {
    path: "/manage",
    element: (
      <PrivateRoute>
        <RegisterUserPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/auth/kakao/callback", // .env의 REACT_APP_REDIRECT_URL 참고
    element:  <KakaoCallback />,
  },
  {
    path: "/resetPassword/:resetToken",
    element:  <Reset />,
  },
  {
    path: "/forgot",
    element:  <Forgot />,
  },
  {
    path: "/register",
    element:  <Register />,
  },
  {
    path: "/company",
    element:  <RegisterCompany />,
  },
  {
    path: "/test",
    element:  <VideoInput />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(<RouterProvider router={appRouter} />);
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={appRouter} />
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

