import React, { useState, useEffect, useRef } from 'react';
// import { withRouter } from 'react-router-dom';
import Webcam from 'react-webcam';
import { loadModels, getFullFaceDescription, createMatcher } from '../utils/face';

// Import face profile
const JSON_PROFILE = require('../descriptors/bnk48.json');

const WIDTH = 420;
const HEIGHT = 420;
const inputSize = 160;


const VideoInput = () => {
  const webcamRef = useRef(null);
  // const [fullDesc, setFullDesc] = useState(null);
  const [detections, setDetections] = useState(null);
  const [descriptors, setDescriptors] = useState(null);
  const [faceMatcher, setFaceMatcher] = useState(null);
  const [match, setMatch] = useState(null);
  const [facingMode, setFacingMode] = useState(null);

  const [isWebcamActive, setIsWebcamActive] = useState(false);

  const toggleWebcam = () => {
    // setIsWebcamActive(prevState => !prevState);
    setIsWebcamActive(!isWebcamActive);
  };

  useEffect(() => {
    const init = async () => {
      await loadModels();
      const matcher = await createMatcher(JSON_PROFILE);
      setFaceMatcher(matcher);
      setInputDevice();
    };
    init();
  }, []);

  const setInputDevice = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    // console.log('devices:', devices);
    // const inputDevice = devices.filter(device => device.kind === 'videoinput');
    const inputDevice = devices.filter(device => device.kind === 'videoinput' && device.label !== "OBS Virtual Camera");
    setFacingMode(inputDevice.length < 2 ? 'user' : { exact: 'environment' })
    // startCapture();
  };

  // const startCapture = () => {
  //   const interval = setInterval(async () => {
  //     await capture();
  //   }, 1500);
  //   return() => clearInterval(interval);
  // };

  useEffect(() => {
    const interval = setInterval(() => {
      capture();
    }, 1500);
    return() => clearInterval(interval);
  }, [facingMode]);

  const capture = async () => {
    if (webcamRef.current) {
      // console.log('caputure ==========');
      // console.log(webcamRef);
      // console.log(webcamRef.current);
      const screenshot = webcamRef.current.getScreenshot();
      // console.log('screenshot:', screenshot);

      if (screenshot !== null) {
        const fullDesc = await getFullFaceDescription(screenshot, inputSize);
        if (fullDesc) {
          setDetections(fullDesc.map(fd => fd.detection));
          setDescriptors(fullDesc.map(fd => fd.descriptor));
          // console.log('detections1:', detections); // null
        }
  
        // console.log('descriptors2:', descriptors); // null
        // if (descriptors && faceMatcher) {
        //   const matches = await descriptors.map(descriptor => faceMatcher.findBestMatch(descriptor));
        //   console.log('matches:', matches);
        //   setMatch(matches);
        // }
      }
    }
  }; // capture

  useEffect(() => {
    // console.log('descriptors3:', descriptors);
    if (descriptors && faceMatcher) {
      const matches = descriptors.map(descriptor => faceMatcher.findBestMatch(descriptor));
      console.log('matches:', matches);
      setMatch(matches);
    }
  }, [detections, descriptors])


  // render() {
    let videoConstraints = null;
    let camera = '';
    if (!!facingMode) {
      videoConstraints = {
        width: WIDTH,
        height: HEIGHT,
        facingMode: facingMode
      };
      if (facingMode === 'user') {
        camera = 'Front';
      } else {
        camera = 'Back';
      }

      // console.log('videoConstraints:', videoConstraints);
    }

    let drawBox = null;
    if (detections) {
      drawBox = detections.map((detection, i) => {
        let _H = detection.box.height;
        let _W = detection.box.width;
        let _X = detection.box._x;
        let _Y = detection.box._y;
        return (
          <div key={i}>
            <div
              style={{
                position: 'absolute',
                border: 'solid',
                borderColor: 'blue',
                height: _H,
                width: _W,
                transform: `translate(${_X}px,${_Y}px)`
              }}
            >
              {!!match && !!match[i] ? (
                <p
                  style={{
                    backgroundColor: 'blue',
                    border: 'solid',
                    borderColor: 'blue',
                    width: _W,
                    marginTop: 0,
                    color: '#fff',
                    transform: `translate(-3px,${_H}px)`
                  }}
                >
                  {match[i]._label}
                </p>
              ) : null}
            </div>
          </div>
        );
      });
    } 
    
    return (
      <div
        className="Camera"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <button onClick={toggleWebcam}>
          {isWebcamActive ? 'Turn Off Webcam' : 'Turn On Webcam'}
        </button>
        <p>Camera: {camera}</p>
        <div
          style={{
            width: WIDTH,
            height: HEIGHT
          }}
        >
          <div style={{ position: 'relative', width: WIDTH }}>
            {!!videoConstraints ? (
              <div style={{ position: 'absolute' }}>
                {isWebcamActive && (
                <Webcam
                  audio={false}
                  width={WIDTH}
                  height={HEIGHT}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                />
                )}
              </div>
            ) : null}
            {!!drawBox ? drawBox : null}
          </div>
        </div>
      </div>
    );
    
  // }
}

// export default withRouter(VideoInput);
export default VideoInput;
