// /frontend/src/pages/Register.js
// https://github.com/Kartik-Katkar/SecureAuth/blob/main/frontend/src/pages/auth/Register.js
import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { BsCheck2All } from "react-icons/bs";
import { TiUserAddOutline } from "react-icons/ti";
import { Link, useNavigate } from "react-router-dom";
import Card from "../Components/card/Card";
import PasswordInput from "../Components/passwordInput/PasswordInput";
import styles from "./auth.module.scss";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { validateEmail } from "../redux/features/auth/authService";
import { useDispatch, useSelector } from "react-redux";
import {
  register,
  // RESET,
  sendVerificationEmail,
} from "../redux/features/auth/authSlice";
import Loader from "../Components/loader/Loader";

// import useGetDepInfo from '../hooks/GetDepInfo';

const initialState = {
  name: "",
  email: "",
  password: "",
  password2: "",
  department: "",
  age: "",
  gender: "",
  position: ""
};

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState(initialState);
  const { name, email, password, password2, department, age, gender, position } = formData;
  // const depInfo = useGetDepInfo();

  // const { isLoading, isLoggedIn, isSuccess, message } = useSelector(
  const { isLoading, error } = useSelector(
    (state) => state.auth
  );
  

  const [uCase, setUCase] = useState(false);
  const [num, setNum] = useState(false);
  // const [sChar, setSChar] = useState(false);
  const [passLength, setPassLength] = useState(false);

  const timesIcon = <FaTimes color="red" size={15} />;
  const checkIcon = <BsCheck2All color="green" size={15} />;

  const switchIcon = (condition) => {
    if (condition) {
      return checkIcon;
    }
    return timesIcon;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    // Check Lower and Uppercase
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
      setUCase(true);
    } else {
      setUCase(false);
    }
    // Check for numbers
    if (password.match(/([0-9])/)) {
      setNum(true);
    } else {
      setNum(false);
    }
    /*// Check for special character
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
      setSChar(true);
    } else {
      setSChar(false);
    }*/
    // Check for PASSWORD LENGTH
    if (password.length > 5) {
      setPassLength(true);
    } else {
      setPassLength(false);
    }
  }, [password]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error])

  const registerUser = async (e) => {
    e.preventDefault();

    try {
      if (!name || !email || !password || !department || !age || !gender || !position) {
        toast.error("All fields are required");
        return;
      }
      if (password.length < 6) {
        toast.error("Password must be up to 6 characters");
        return;
      }
      if (!validateEmail(email)) {
        toast.error("Please enter a valid email");
        return;
      }
      if (password !== password2) {
        toast.error("Passwords do not match");
        return;
      }
  
      const userData = {
        name,
        email,
        password,
        department,
        age: parseInt(age, 10),
        gender: parseInt(gender, 10),
        position
      };
  
      // console.log(userData);
      await dispatch(register(userData)).unwrap()
        .then(() => {
          setFormData(initialState); // 등록이 성공하면 리셋
          toast.success("Registeration successful")
        });
      // await dispatch(sendVerificationEmail());


    } catch (error) {
      toast.error(error.message || "Registeration failed")
    }
  };

  // useEffect(() => {
  //   if (isSuccess && isLoggedIn) {
  //     navigate("/profile");
  //   }

  //   dispatch(RESET());
  // }, [isLoggedIn, isSuccess, dispatch, navigate]);

  return (
    <div className={`container ${styles.auth}`}>
      {isLoading && <Loader />}
      <Card>
        <div className={styles.form2}>
          <div className="--flex-center">
            <TiUserAddOutline size={35} color="#999" />
          </div>
          <h2>Register</h2>

          <form onSubmit={registerUser}>
            <input
              type="text"
              placeholder="Name"
              required
              name="name"
              value={name}
              onChange={handleInputChange}
            />
            <input
              type="email"
              placeholder="Email"
              required
              name="email"
              value={email}
              onChange={handleInputChange}
            />
            <PasswordInput
              placeholder="Password"
              name="password"
              value={password}
              onChange={handleInputChange}
            />
            <PasswordInput
              placeholder="Confirm Password"
              name="password2"
              value={password2}
              onChange={handleInputChange}
              onPaste={(e) => {
                e.preventDefault();
                toast.error("Cannot paste into input field");
                return false;
              }}
            />
            
            {/* <input
              type="text"
              placeholder="Department"
              required
              name="department"
              value={department}
              onChange={handleInputChange}
            /> */}
            <select
              name="department"
              value={department}
              onChange={handleInputChange}
              required
            >
              <option value="" disabled>Select Department</option>
              <option value="482af53c-ff7c-4537-a957-541593767c5d">Books</option>
              <option value="658312c8-2219-4199-af82-6de3635166f9">Music</option>
            </select>
            <input
              type="text"
              placeholder="Age"
              required
              name="age"
              value={age}
              onChange={handleInputChange}
            />
            <input
              type="text"
              placeholder="Position"
              required
              name="position"
              value={position}
              onChange={handleInputChange}
            />
            {/* <input
              type="text"
              placeholder="Gender"
              required
              name="gender"
              value={gender}
              onChange={handleInputChange}
            /> */}
            <div className="gender">
              <input
                type="radio"
                id="male"
                name="gender"
                value="1"
                checked={gender === "1"}
                onChange={handleInputChange}
              />
              <label htmlFor="male">남자</label>
              <input
                type="radio"
                id="female"
                name="gender"
                value="2"
                checked={gender === "2"}
                onChange={handleInputChange}
              />
              <label htmlFor="female">여자</label>
            </div>


            {/* Password Strength */}
            <Card cardClass={styles.group}>
              <ul className="form-list">
                <li>
                  <span className={styles.indicator}>
                    {switchIcon(uCase)}
                    &nbsp; Lowercase & Uppercase
                  </span>
                </li>
                <li>
                  <span className={styles.indicator}>
                    {switchIcon(num)}
                    &nbsp; Number (0-9)
                  </span>
                </li>
                {/* <li>
                  <span className={styles.indicator}>
                    {switchIcon(sChar)}
                    &nbsp; Special Character (!@#$%^&*)
                  </span>
                </li> */}
                <li>
                  <span className={styles.indicator}>
                    {switchIcon(passLength)}
                    &nbsp; At least 6 Character
                  </span>
                </li>
              </ul>
            </Card>

            <button type="submit" className="--btn --btn-primary --btn-block">
              Register
            </button>
            <ToastContainer 
              position="top-right" // 알람 위치
              autoClose={3000} // 자동 off 시간, 3000 ms = 3 sc
              // hideProgressBar={false} // 진행시간바 숨김
              pauseOnHover={false} // 마우스 오버 시 진행바 멈춤 비활성화
              limit={3} // 알람 개수 제한
            />
          </form>

          {/* <span className={styles.register}> */}
          <span className={styles.links}>
            {/* <Link to="/">Home</Link> */}
            {/* <p> &nbsp; Already have an account? &nbsp;</p> */}
            <Link to="/">Login</Link>
          </span>
        </div>
      </Card>
    </div>
  );
};

export default Register;
