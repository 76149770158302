// /frontend/src/Components/ExportCSV.js
import React from 'react';
import { utils, writeFile } from 'xlsx';

const ExportCSV = ({ data, filename, columns, label }) => {
  const handleExport = () => {
    // 필터링
    const exportData = data.map(row =>
      columns.reduce((obj, key) => ({...obj, [key]: row[key] }), {})
    );

    // 데이터를 CSV 파일로 변환
    const headings = [columns] // 열 헤더
    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet(headings);
    utils.sheet_add_json(ws, exportData, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Data"); // 엑셀 시트명

    // CSV 파일로 저장
    writeFile(wb, `${filename}.csv`);
  };

  return (
    <button className="search-btn" onClick={handleExport}>
      {label}
    </button>
  );
};

export default ExportCSV;
