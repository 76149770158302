import React from "react";

function Footer() {
  return (
    <div className="footer">
      <h4 className="text-align">Copyright © 2023 . All rights are reserved</h4>
    </div>
  );
}

export default Footer;
