// /frontend/src/pages/RegisterCompany.js
import React, { useState } from "react";
import { TiUserAddOutline } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import Card from "../Components/card/Card";
// import PasswordInput from "../Components/passwordInput/PasswordInput";
import styles from "./auth.module.scss";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// import { validateEmail } from "../redux/features/auth/authService";
import { useDispatch, useSelector } from "react-redux";
// import {
//   register,
//   // RESET,
//   sendVerificationEmail,
// } from "../redux/features/auth/authSlice";
import Loader from "../Components/loader/Loader";
import axios from '../axios';

import useUserLogin from "../hooks/UserLogin";
import useGetUserInfo from '../hooks/GetUserInfo';

const initialState = {
  company: ""
};

const RegisterCompany = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState(initialState);
  const { company } = formData;
  // const depInfo = useGetDepInfo();
  const { isLoading, error } = useSelector(
    (state) => state.auth
  );
  const { login } = useUserLogin();
  const { sendGetUserInfoReq } = useGetUserInfo();

  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const multiTenantUrl = process.env.REACT_APP_MULTI_TENANT_URL;
  const mongoUri = process.env.REACT_APP_MONGO_URI;

  let email;
  let tenant;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const registerCompany = async (e) => {
    e.preventDefault();

    try {
      // console.log('formData:', formData);
      // console.log('multiTenantUrl:', multiTenantUrl);
      // console.log('mongoUri:', mongoUri);

      // 1. mt_admin 테넌트 목록 가져오기
      const getTenantsRes = await axios.get(`${multiTenantUrl}/api/v1/admin/tenant`)
      const tenants = getTenantsRes.data.tenants;

      // 2. mt_admin 테넌트 유무 확인
      const existingTenant = tenants.find(t => t.name === company);
      email = localStorage.getItem('email');

      if (existingTenant) {
        tenant = existingTenant.name;
      } else {
        const newTenantRes = await axios.post(`${multiTenantUrl}/api/v1/admin/tenant`, {
          name: company,
          dbURI: mongoUri
        });

        tenant = newTenantRes.data.tenant.name;
      }

      if (tenant) {
        localStorage.setItem('tenant', tenant);

        // 3. mt_admin 유저 업데이트(tenant)
        const updateUserRes  = await axios.patch(
          `${serverUrl}/users/adminDbUpdate`, {
            email: email,
            tenant: tenant
          }
        );

        // 4. mt_{테넌트} 유저 생성
        // 5. (백엔드에서) seed 데이터 생성
        const headers  = {
          headers: {
            tenant: tenant
          }
        };
        const data = {
          email: email
        };

        if (updateUserRes.status === 200) {
          const createUserRes = await axios.post(
            `${serverUrl}/users/init`, data, headers
          );

          // console.log(createUserRes.status);
          // console.log(createUserRes.data);

          if (createUserRes.status === 200) {
            // 6. mt_{테넌트} 유저 로그인
            await login(email, "atmspassword");
            

            // 7. mt_{테넌트} isAdmin 유무 확인
            await sendGetUserInfoReq(localStorage.getItem('token'));

          } else {
            console.log("creat seed data Error");
          }
        } else {
          console.log("user update Error");
        }


        navigate('/overview');
      }
  
    } catch (error) {
      toast.error(error.message || "Company Registeration failed")
    }
  };


  return (
    <div className={`container ${styles.auth}`}>
      {isLoading && <Loader />}
      <Card>
        <div className={styles.form2}>
          <div className="--flex-center">
            <TiUserAddOutline size={35} color="#999" />
          </div>
          <h2>Input Company Name</h2>

          <form onSubmit={registerCompany}>
          {/* <form> */}
            <input
              type="text"
              placeholder="ex. zocst in case of ZCOST Inc"
              required
              name="company"
              value={company}
              onChange={handleInputChange}
            />

            <button type="submit" className="--btn --btn-primary --btn-block">
              Enter
            </button>
            
            <ToastContainer 
              position="top-right" // 알람 위치
              autoClose={3000} // 자동 off 시간, 3000 ms = 3 sc
              // hideProgressBar={false} // 진행시간바 숨김
              pauseOnHover={false} // 마우스 오버 시 진행바 멈춤 비활성화
              limit={3} // 알람 개수 제한
            />
          </form>

          {/* <span className={styles.register}> */}
          {/* <span className={styles.links}> */}
            {/* <Link to="/">Home</Link> */}
            {/* <p> &nbsp; Already have an account? &nbsp;</p> */}
            {/* <Link to="/">Login</Link>
          </span> */}
        </div>
      </Card>
    </div>
  );
};

export default RegisterCompany;
