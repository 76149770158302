// /frontend/src/Components/PrivateRoute.js
import { Navigate } from 'react-router-dom';

/*
const PrivateRoute = ({ children }) => {
    const isLogin = !!localStorage.getItem('token'); // 로그인 여부 확인

    return isLogin ? children : <Navigate to="/" />;
};

export default PrivateRoute;
*/

const PrivateRoute = ({ children, adminOnly = false }) => {
  const isLogin = !!localStorage.getItem('token'); // 로그인 여부 확인
  const isAdmin = JSON.parse(localStorage.getItem('isAdmin')); // 관리자 여부 확인

  // 관리자 전용 페이지인 경우
  if (adminOnly && (!isLogin || !isAdmin)) {
      return <Navigate to="/" />;
  }

  // 일반 사용자 전용 페이지
  return isLogin ? children : <Navigate to="/" />;
};

export default PrivateRoute;
