import React from 'react';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const PieChart = ({ male, female, other }) => {
  const data = {
    labels: ['Male', 'Female', 'Other'],
    datasets: [
      {
        label: 'Current Number of Gender',
        data: [male, female, other],
        backgroundColor: [
            'rgba(54, 162, 235, 0.2)', // blue
            'rgba(255, 99, 132, 0.2)', // rewd
            'rgba(255, 206, 86, 0.2)', // yeloow
        ],
        borderColor: [
            'rgba(54, 162, 235, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 3,
      }
    ]  
  };
  const options = {
    plugins: {
      datalabels: {
        font: {
            size: 18
        }, /*
        formatter: (value, ctx) => {
          let sum = ctx.datasets.data.reduce((a, b) => a + b, 0);
          let percentage = (value * 100 / sum).toFixed(2) + '%';
          return percentage;  
        } */
      },
    },
  };
  return (
    <div className='pie-chart'>
      <h4 className="align">Staff by Gender</h4>
      <Pie data={data} options={options} plugins={[ChartDataLabels]} />
    </div>
  );
};

export default PieChart;
