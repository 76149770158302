// /frontend/src/Components/RegiserUserPage.js
import React, { useEffect, useState } from 'react';
import { read, utils, writeFile } from 'xlsx'; // npm install xlsx

import useRegisterUser from '../hooks/RegisterUser';
import useGetDepInfo from '../hooks/GetDepInfo';
import useFetchData from "../hooks/DataFetch";

import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import ExportCSV from '../Components/ExportCSV';


const RegisterUserPage = () => {
  const [users, setUsers] = useState([]);
  // const [files, setFiles] = useState([]);
  const [searchNewStaffText, setSearchNewStaffText] = useState("");
  const [searchStaffText, setSearchStaffText] = useState("");
  const [filteredNewUsers, setFilteredNewUsers] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const { registerUser } = useRegisterUser();
  const depInfo = useGetDepInfo();
  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const { data, error } = useFetchData(`${serverUrl}users`);
/*
  useEffect(() => {
    if (data) {
      setFilteredUsers(data);
    }
  }, [data]);
*/
  const handleImport = $event => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = event => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          setUsers(rows);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  /*
  const heandleExport = () => {
    const exportData = filteredUsers === null ? users : filteredUsers; // 검색 결과나 전체

    const headings = [['name', 'password', 'age', 'gender', 'department', 'position', 'email']]; // <thead> 태그와 세트
    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet(headings);

    utils.sheet_add_json(ws, exportData, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Report"); // 엑셀 시트명
    // writeFile(wb, "Report.xlsx") 

    // CSV 파일로 저장
    const csvOutput = utils.sheet_to_csv(ws);
    const blob = new Blob([csvOutput],  {type: 'text/csv;charset=utf-8;'});
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Report.csv';
    link.click();
  };
  */
  const exportData = filteredNewUsers === null ? users : filteredNewUsers; // 검색 결과나 전체
  const exportColums = ['name', 'password', 'age', 'gender', 'department', 'position', 'email'];

  const handleNewStaffSearch = () => {
    if (searchNewStaffText.trim() === '') {
      setFilteredNewUsers(null); // 검색 기록이 비어있으면 전체 목록 표시
    } else {
      const filteredData = users.filter(user =>
        user.name.toLowerCase().includes(searchNewStaffText.toLowerCase())
      );
      setFilteredNewUsers(filteredData); // 검색 결과 저장
    }
  };

  const handleStaffSearch = () => {
    // const filterdData = data.filter((res) => 
    //   res.name.toLowerCase().includes(searchStaffText.toLowerCase())
    // );
    // setFilteredUsers(filterdData);

    if (searchStaffText.trim() === '') {
      setFilteredUsers(null);
    } else {
      const filterdData = data.filter(user => 
        user.name.toLowerCase().includes(searchStaffText.toLowerCase())
      );
      setFilteredUsers(filterdData);
    }
  };

  const handleRegisterUsers = async () => {
    // 1. CSV 파일에서 가져온 사용자 데이터에 맞게 department 값을 매핑
    const mappedUsers = users.map(user => {
      // console.log("Mapping before user:", user); 
      const department = depInfo.find(d => d.name === user.department);
      // console.log("Found department for", user.department, ":", department);
      return {
        ...user,
        department: department ? department._id : ''
      };
    });
    
    // 2. restAPI로 백엔드에 직원 등록
    let successCount = 0;

    for (const user of mappedUsers) {
      try {
        const result = await registerUser(user);
        if (!result.success) {
          alert(`Failed to register user ${user.name}: ${result.message}.`);
          // console.log(`Failed to register user ${user.name}: ${result.message}.`);
          break;
        } else {
          successCount++;
        }
      } catch (error) {
        console.error(`Error registering user ${user.name}:`, error);
      }
    }

    if (successCount === mappedUsers.length) {
      alert('All staff have been successfully registered.');
    // } else {
    //   alert(`${successCount} out of ${mappedUsers.length} staff have been registered.`);
    }
  };

  /*
  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  const handleFileUpload = async () => {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('photos', file); // 파일을 formData에 추가
    });

    // 파일 전송 로직
    // await axios.post('your_upload_endpoint', formData);
  };
  */


  // const columns = [
  //   "_id", "isAdmin", "avaliable", "absenceDays", "email", "name", "age", "gender", "department", "position", "headOfDepartmentId", "attendance", "salt", "hash", "createdAt", "updatedAt", "__v", "imageURL"
  // ];
  const columns = ['name', 'age', 'gender', 'department', 'position', 'email'];

  // const dataArray = Object.values(data);
  // useEffect(() => {
  //   console.log('data:', data);
  // }, [data]);


  return (
    <>
    <div>
      <Navbar />

      {/* 검색 div */}
      <div className=" flex justify-center flex-align search-div">
        <input
          className="search" type="text"
          placeholder="Search your name here"
          value={searchNewStaffText}
          onChange={(e) => {
            setSearchNewStaffText(e.target.value);
          }}
        ></input>
        <button
          className="search-btn"
          onClick={() => handleNewStaffSearch()}
        >
          Search
        </button>
      </div>

      {/* csv 파일에 대한 테이블 div */}
      <b className="flex justify-center black">Manage Staff Profile</b>
      <div className="attendance-table">
        <table>
        <thead>
          <tr>
          <th className="th" scope="col">No</th>
          <th className="th" scope="col">Name</th>
          <th className="th" scope="col">Age</th>
          <th className="th" scope="col">Gender</th>
          <th className="th" scope="col">department</th>
          <th className="th" scope="col">position</th>
          <th className="th" scope="col">email</th>
          </tr>
        </thead>
        <tbody>
          {exportData.length ? (
            exportData.map((user,index) => (
                <tr key={index}>
                <td>{index + 1}</td>
                <td>{user.name}</td>
                <td>{user.age}</td>
                <td>{user.gender}</td>
                <td>{user.department}</td>
                <td>{user.position}</td>
                <td>{user.email}</td>
                </tr>
            ))
          ) : (
          <tr>
              <td colSpan="8" className="text-center">
              No Users Found.
              </td>
          </tr>
      
          )}
        </tbody>
        </table>
      </div>


      {/* 직원 등록 관련 버튼 */}
      <div className='flex justify-center flex-align search-div'>
        <label htmlFor='file-upload' className='search-btn'>
          Import CSV<i className='fa fa-upload'></i>
        </label>
        <input
          id="file-upload" type='file' name='file'
          required onChange={handleImport}
          accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          style={{ display: 'none'}}
        />

        <ExportCSV 
          data={exportData}
          filename="RegisteredUsers"
          columns={exportColums}
          label="Export CSV"
        />
        {/* <button className="search-btn" onClick={heandleExport}>
          Export CSV<i className='fa fa-download'></i>
        </button> */}
        {/* <br /> */}
        <button className="search-btn" onClick={handleRegisterUsers} >
          Register USER
        </button>

        {/* <br /><br /><br /><br /> */}
      </div>

      {/* 직원 사진 등록 관련 버튼 => 아직 구현 안 함*/}
      {/* <div className='flex justify-center flex-align search-div'>
        <input
          className="search-btn" type="file" name="photos"
          multiple onChange={handleFileChange}
        />
        <button className="search-btn" onClick={handleFileUpload}>
          Upload Photos
        </button>
      </div> */}



      {/* 검색 div */}
      <div className=" flex justify-center flex-align search-div">
        <input
          className="search" type="text"
          placeholder="Search your name here"
          value={searchStaffText}
          onChange={(e) => {
            setSearchStaffText(e.target.value);
          }}
        ></input>
        <button
          className="search-btn"
          onClick={() => handleStaffSearch()}
        >
          Search
        </button>
      </div>
      {/* 전 직원 테이블 */}
      <b className="flex justify-center black">All Staff Profile</b>
      <div className="attendance-table">
      <table>
        <thead>
          <tr>
          {columns.map((column, index) => (
            <th key={index} className='th' scope='col'>{column}</th>
          ))}
          </tr>
        </thead>
        <tbody>
          {filteredUsers && filteredUsers.length > 0 ? (
            filteredUsers.map((user, index) => (
              <tr key={index}>
              {columns.map((column, idx) => (
                <td key={idx}>              
                {typeof user[column] === 'object' && user[column] !== null
                  ? user[column].name
                  : user[column]
                }
                {/* {user[column]} */}
                </td>
              ))}
              </tr>
            ))
          ) : (
            <tr>
            <td colSpan={columns.length} className="text-center">
            No Users Found.
            </td>
            </tr>
          )}
        </tbody>
        </table>
      </div>
      
      
      <div className='flex justify-center flex-align search-div'>
      {/* <div className="attendance-table"> */}
        <ExportCSV 
          data={data}
          filename="StaffList"
          columns={columns}
          label="Export All Staff CSV"
        />
      </div>

      <Footer />
    </div>
    </>
  )

};


export default RegisterUserPage;

