import { useState, useEffect } from "react";
import axios from '../axios';

// data load using mongodb
const useFetchData = (url) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);
  const [tenant, setTenant] = useState(null);

  useEffect(() => {
    // 로컬 스토리지에서 토큰을 검색하고 상태를 업데이트합니다.
    const storedToken = localStorage.getItem('token');
    const storedTenant = localStorage.getItem('tenant');
    
    if (storedToken) {
      setToken(storedToken);
    }

    if (storedTenant) {
      setTenant(storedTenant);
    }
  }, []);

  //console.log('token:', token);
  useEffect(() => {
    if (token && tenant) {
      const headers = {
        headers: {
          'tenant': tenant,
          'Authorization': `Bearer ${token}`
        }
      };

      axios.get(url, headers)
        .then(response => {
          setData(response.data);
          //setFilteredData(response.data);
        })
        .catch(error => {
          //console.error('Error fetching data: ', error);
          setError(error);
        });
    }
  }, [url, token, tenant]);

  return { data, error };
};

export default useFetchData;
