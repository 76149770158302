import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

const BarChart = ({ attendanceRates }) => {

  const monthColors = {
    'Jan': 'rgb(255, 99, 132)',
    'Feb': 'rgb(54, 162, 235)',
    'Mar': 'rgb(255, 206, 86)',
    'Apr': 'rgb(75, 192, 192)',
    'May': 'rgb(153, 102, 255)',
    'Jun': 'rgb(255, 159, 64)',
    'Jul': 'rgb(255, 99, 132)',
    'Aug': 'rgb(54, 162, 235)',
    'Sep': 'rgb(255, 206, 86)',
    'Oct': 'rgb(75, 192, 192)',
    'Nov': 'rgb(153, 102, 255)',
    'Dec': 'rgb(255, 159, 64)',
  };

  const data = {
    labels: attendanceRates.map(rate => rate.month),
    datasets: [
      {
        label: 'Attendance Rate (%)',
        data: attendanceRates.map(rate => rate.rate),
        // backgroundColor: [
        //   'rgba(255, 99, 132, 0.2)',
        //   'rgba(54, 162, 235, 0.2)',
        // ],
        // borderColor: [
        //     'rgba(255, 99, 132, 1)',
        //     'rgba(54, 162, 235, 1)',
        // ],
        backgroundColor: attendanceRates.map(rate => {
          // monthColors[rate.month] ? `${monthColors[rate.month]}0.2` : 'rgba(0, 0, 0, 0.2)'
          const monthShort = rate.month.split(' ')[0];
          const rgb = monthColors[monthShort];
          return rgb ? `rgba(${rgb.substring(4, rgb.length -1)},0.2)` : 'rgba(0, 0, 0, 0.2)'
        }),
        borderColor: attendanceRates.map(rate => {
          const monthShort = rate.month.split(' ')[0];
          const rgb = monthColors[monthShort];
          return rgb ? `rgba(${rgb.substring(4, rgb.length -1)},1)` : 'rgba(0, 0, 0, 1)'
        }),
        borderWidth: 3,
      }
    ]
  };
  /*
  const data ={ 
    labels: attendanceRates.map(emp => emp.name),
    datasets: [
      {
        label: 'Attendance Rate (%)',
        data :attendanceRates.map(emp => emp.rate),
        borderWidth: 1,
      },
    ]
  }; 
  */

  const options = {
    scales: {
      y: { // 'yAxes' 대신 'y' 사용
        beginAtZero: true,
        max: 100,
      }
    },
    plugins: {
      legend: {
        display: false
      },
    },        
  };  

  return (
    <div className='bar-graph'>
      <h4 className="align">Monthly Attendance Rate</h4>
      <Bar data = { data }  options = {options}/>
    </div>
  );
};

export default BarChart;
