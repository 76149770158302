import React from 'react';

const TopAttendanceTable = ({ absentees }) => {
  return (
    <div className="attendant">
      <h4 className="align">Top 5 Attendant</h4>
      {/* <div className="flex">
        <div className="mr">Name</div>
        <div className="mr">Last Month</div>
        <div className="mr">This Month</div>
      </div> */}
      <div className="flex">
        <div className="mr">
          <p><strong>Name</strong></p>
          {absentees.map((em, index) => <p key={index}>{em.name}</p>)}
        </div>
        <div className="mr">
          <p><strong>Last Month</strong></p>
          {absentees.map((em, index) => <p key={index}>{em.previousMonthPercentage}%</p>)}
        </div>
        <div className="mr">
          <p><strong>This Month</strong></p>
          {absentees.map((em, index) => <p key={index}>{em.currentMonthPercentage}%</p>)}
        </div>
      </div>
    </div>
  );
};

export default TopAttendanceTable;
