// Export csv 기능 추가해야함
// /front/src/Components/AttendanceTimeTable.JS
import React from 'react';

const AttendanceTimeTable = ({ userData }) => {
  // HH:MM
  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  return (
    // <div className="attendant">
    <div className=''>
      {/* <h4 className="align">Employee Attendance Time</h4> */}
      <h4 className="align">Late Attendance Today</h4>
      <table>
        <thead>
          <tr>
            <th className='th'>No</th>
            <th className='th'>Name</th>
            <th className='th'>Check-In Time</th>
          </tr>
        </thead>
        <tbody>
          {userData.map((user,index) => (
            <tr key={user._id}>
              <td>{index+1}</td>
              <td>{user.name}</td>
              {/* <td>{user.lastEntry}</td> */}
              <td>{user.lastEntry === 'No Check In' ? user.lastEntry : formatTime(user.lastEntry)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AttendanceTimeTable;
