// frontend/src/Components/KakaoCallback.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../axios";
import useUserLogin from "../hooks/UserLogin";
import useGetUserInfo from '../hooks/GetUserInfo';

function KakaoCallback() {
  let tenant;

  const navigate = useNavigate();
  const { login } = useUserLogin();
  const { sendGetUserInfoReq } = useGetUserInfo();

  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const url = new URL(window.location.href);
  const authCode = url.searchParams.get("code");
  // const [requestSent, setRequestSent] = useState(false);
  
  useEffect(() => {
    const fetchData = async () => {
      // if (authCode && !requestSent) {  
        // setRequestSent(true); 
      if( authCode) {   
        try {
          // 1. 카카오 인증코드로 mt_admin 유저 로그인 => email, tenant
          const adminLoginRes = await axios.get(`${serverUrl}users/auth/kakao?code=${authCode}`);
          tenant = adminLoginRes.data.tenant;
          // console.log(response.data);

          if (adminLoginRes.data && adminLoginRes.data.token) {
            localStorage.setItem("email", adminLoginRes.data.email);
          }

          if (!tenant) {
            navigate("/company"); // 테넌트명이 없으면 리디랙션
          } else {
            localStorage.setItem("tenant", tenant); // 로컬스토리지에 저장

            // 2. mt_{테넌트} 유저 로그인 => token, userId
            await login(adminLoginRes.data.email, "atmspassword");

            // 3. => isAdmin
            // useGetUserInfo(localStorage.getItem('token'));
            sendGetUserInfoReq(localStorage.getItem('token'));

            navigate("/overview"); // 로그인 성공시 /overview"로 리디렉션
          }
          
        } catch (error) {
          localStorage.clear();
          console.error("Error kakao login:", error);
        }
      }
    };

    fetchData();
  }, [navigate, authCode]);

  return (
    <div>Loading...</div>
  );
}

export default KakaoCallback;
