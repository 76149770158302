// /frontend/src/Components/Navbar.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
// import { Link, useNavigate } from "react-router-dom";

import logo from "../images/logo.png";
import userIcon from "../images/user.png";

import useGetUserInfo from '../hooks/GetUserInfo';

function Navbar() {
  // const navigate = useNavigate(); // 리디렉션에 사용할 훅
  const storedToken = localStorage.getItem('token');
  const { userName, userImageUrl, loading } = useGetUserInfo(storedToken);
  // const { userName, userImageUrl, isAdmin, loading } = useGetUserInfo();
  const [dropDownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropDownOpen);
  };

  const logout = () => {
    // localStorage.removeItem('token');
    // localStorage.removeItem('userId');
    localStorage.clear(); // 로컬스토리지 토큰 초기화
    window.location.href = '/'
  }

  return (
    <div className="flex navbar">
      <div className="flex flex-align">
        <img src={logo} className="logo" alt="logo"></img>
        <ul className="ul">
          <Link className="links" to="/overview">
            <li className="list-items">Overview</li>
          </Link>

          
          <Link className="links" to="/attendance">
            <li className="list-items">
              Manage Attendance
              {/* Check-In */}
            </li>
          </Link>
          
          <Link className="links" to="/manage">          
            <li className="list-items">
              Manage Staffs
              {/* Staffs */}
            </li>
          </Link>
        </ul>
      </div>
      
      {/* /login 페이지로 이동 */}
      {/* <div className="flex flex-align user-data">
        <img src={userImageUrl || userIcon} alt="user" className="user"></img>
        <Link className="links" to="/">
        {storedToken ? (
          <p>{userName || 'Unknown'}</p>
        ) : (
          'Login'
        )}
        </Link>
      </div> */}

      {/* 로그아웃 small 윈도우 창 */}
      <div className="flex flex-align user-data" onClick={toggleDropdown}>
        <img src={userImageUrl || userIcon} alt="user" className="user"></img>
        {storedToken && !loading ? (
          <React.Fragment>
            <p>{userName || 'Unknown'}</p>
            {dropDownOpen && (
              <div className="dropdown-menu">
              <div className="dropdown-item" >Profile</div>
                <div className="dropdown-item" onClick={logout}>Logout</div>
              </div>
            )}
          </React.Fragment>
        ) : (
          <Link className="links" to="/">
            Login
          </Link>
        )}
      </div>


    </div>
  );
}

export default Navbar;

