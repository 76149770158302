// frontend/src/hooks/UserCheckIn.js
// import { useState, useEffect } from "react";
// import axios from 'axios';
import axios from '../axios';

const useRegisterUser = () => {
  const serverUrl = process.env.REACT_APP_BACKEND_URL;

  const registerUser = async (user) => {
    try {
      const storedToken = localStorage.getItem('token');
      const storedTenant = localStorage.getItem('tenant');
      if (storedToken) {
        const headers = { 
          headers: {
            'tenant': storedTenant,
            'Authorization': `Bearer ${storedToken}`
          }
        };
        const response = await axios.post(`${serverUrl}users/register`, user, headers);

        //console.log(response.data);

        if (response.status === 200) {
          return { success: true };
        } else {
          return { success: false, message: response.err.message };
        }
      }
    } catch (error) {
      // console.error('Error Register User:', error);
      return { success: false, message: error.response?.data?.err?.message || error.message };;
    }
  };

  return { registerUser };
};

export default useRegisterUser;
