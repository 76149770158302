import React from 'react';
import { Line } from 'react-chartjs-2';

const LineChart = ({ dates, attendanceCounts }) => {
  const data = {
    labels: dates,
    datasets: [
      {
        label: 'Daily Attendance',
        data: attendanceCounts,
        fill: false,
        // pointRadius: 0,
        borderColor: ['limegreen'],
        // backgroundColor: ['limegreen'],
        // pointBackgroundColor: ['limegreen'],
        // pointBorderColor: ['limegreen']
      },
    ],
  };
  const options = {
    title: {
      display: true,
      text: 'Line Chart'
    },
    scales: {
      y: { // 'yAxes' 대신 'y' 사용
        beginAtZero: true,
        ticks: {
          stepSize: 1 // y축 눈금 1단위로 설정
        }
      },
    },
    plugins: {
      legend: {
        display: false
      },
    },
  };
  return (

    <div className='line-chart'>
      <h4 className="align">Total Attendance Report</h4>
      <Line data={data} options={options} />
    </div>

  );
};

export default LineChart;
