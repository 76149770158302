import axios from 'axios';

axios.interceptors.response.use(
  response => response, // 성공 응답은 그대로 반환
  error => {
    // 401 Unauthorized 에러 처리
    if (error.response && error.response.status === 401) {
      localStorage.clear(); // 로컬 스토리지 클리어
      window.location.href = '/'; // 로그인 페이지로 리디렉션
    }
    return Promise.reject(error);
  }
);

export default axios;
